<template>
  <section class="col-7">
    <modal-loading
      :is-loading="loading"
      :message="loadingMessage"
      :style="{ zIndex: 1000 }"
    />
    <modal-confirmation
      :state-modal="isModal"
      title="削除してもよろしいですか？"
      color="red"
      label="削除"
      @hideModal="toggleModal"
      @apply="deleteItem"
    />
    <h2 v-if="$route.meta === 'new'" class="mb-10">写真新規登録</h2>
    <h2 v-if="$route.meta === 'edit'" class="mb-10">写真の編集</h2>

    <section>
      <div>
        <div>
          <div class="subtitle-1 mb-2">写真の選択</div>
          <div v-if="photoData.id && !showCancel">
            画像ID {{ photoData.id }}
          </div>
          <section
            v-if="
              ($route.meta === 'new' || showUploadArea) &&
              !preview &&
              !previewFileName
            "
            class="photos__upload_area"
            :class="
              errorMessages.image || $route.meta === 'edit' ? 'mb-4' : 'mb-12'
            "
            @dragleave.prevent
            @dragover.prevent
            @drop.prevent="onFileChange"
          >
            <p class="mb-11">
              アップロードしたい写真をここにドロップ<br />
              (.png, .jpgに対応しています)
            </p>
            <p class="mb-12">または</p>
            <v-btn rounded outlined @click="selectFile"> ファイルを選択 </v-btn>
            <input ref="file" type="file" @change="onFileChange" />
          </section>
          <section
            v-if="preview && previewFileName"
            :class="
              errorMessages.image || $route.meta === 'edit' ? 'mb-4' : 'mb-12'
            "
          >
            <div>
              {{ previewFileName }}
              <span :style="{ cursor: 'pointer' }" @click="reset">×</span>
            </div>
            <div :style="{ position: 'relative' }">
              <v-img :src="preview" width="770px" />
              <svg
                v-if="
                  !$store.state.photo.isProcessingHighlight &&
                  mosaicIds.length !== 0
                "
                :viewBox="`0 0 ${photoData.image_width || 0} ${
                  photoData.image_height || 0
                }`"
                :style="{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  maxWidth: '770px',
                }"
              >
                <template
                  v-for="(item, index) in $store.state.photo.boundingPoly"
                >
                  <rect
                    :key="`rect-${index}`"
                    :x="item.x"
                    :y="item.y"
                    :width="item.width"
                    :height="item.height"
                    stroke="#00e817"
                    fill="none"
                    :stroke-width="mosaicIds.includes(index) ? 5 : 0"
                  />
                  <text
                    :key="`text-${index}`"
                    :x="item.x"
                    :y="item.y"
                    font-size="7vw"
                    fill="#00e817"
                  >
                    {{ index }}
                  </text>
                </template>
              </svg>
            </div>
          </section>
          <div v-if="$route.meta === 'edit' && showCancel" class="mb-12">
            <v-btn outlined @click="uploadCancel"> キャンセル </v-btn>
          </div>
          <figure
            v-if="$route.meta === 'edit' && showPhotoEdit"
            class="mb-10 d-flex flex-column align-center"
          >
            <v-img :src="photoData.image" width="770px" class="mb-2" />
            <v-btn min-height="36px" rounded outlined @click="photoEdit">
              画像を編集する
            </v-btn>
          </figure>
          <v-alert v-if="errorMessages.image" text color="error" class="mb-8">
            {{ errorMessages.image }}
          </v-alert>
        </div>
        <div
          v-if="
            preview &&
            ['nisshin', 'takahama', 'sample'].includes(
              $store.getters['auth/client'].slug
            )
          "
          class="mb-12"
        >
          <v-btn outlined class="mb-4" @click="startDetections">
            顔を検出する
          </v-btn>
          <div v-if="$store.state.photo.isProcessingHighlight">
            <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>
          <div
            v-if="
              !$store.state.photo.isProcessingHighlight &&
              mosaicIds.length !== 0
            "
          >
            <p class="mb-0">モザイクをかける顔の番号を選択してください。</p>
            <div class="row pl-3 mb-4">
              <v-checkbox
                v-for="id in $store.state.photo.faceIds"
                :key="id"
                v-model="mosaicIds"
                :value="id"
                :label="`${id}`"
                hide-details
                class="mr-5"
              />
            </div>
          </div>
        </div>
        <div
          v-if="
            !$store.state.photo.isProcessingHighlight && mosaicIds.length !== 0
          "
          class="mb-12"
        >
          <v-btn outlined class="mb-4" @click="createMosaic">
            モザイクをかける
          </v-btn>
          <div v-if="$store.state.photo.isProcessingMosaic">
            <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>
          <div v-if="mosaicPhoto">
            <div class="mb-4">
              <v-img :src="mosaicPhoto" width="770px" />
            </div>
            <v-btn outlined @click="replacePhotoToMosaic">
              この画像を登録する
            </v-btn>
          </div>
        </div>

        <div v-if="$route.meta === 'edit'" class="mb-8">
          <p>登録者：{{ userName }}</p>
        </div>

        <div>
          <v-text-field
            v-model="photoData.title"
            type="text"
            label="タイトル"
            placeholder="タイトルを入力してください"
            :class="errorMessages.title ? 'mb-4' : ''"
            :error-messages="errorMessages.title"
            required
            outlined
          />
        </div>

        <div>
          <v-select
            v-model="photoData.categories"
            label="カテゴリ"
            :items="categories"
            item-text="name"
            item-value="id"
            multiple
            chips
            outlined
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                :color="item.settings.pin_color"
                class="white--text pa-6"
                label
              >
                <v-icon left> mdi-shape-outline </v-icon>
                {{ item.name }}
              </v-chip>
            </template>
          </v-select>
        </div>

        <div>
          <v-textarea
            v-model="photoData.description"
            label="写真の説明"
            placeholder="写真の説明文を入力してください"
            :class="errorMessages.description ? 'mb-4' : ''"
            :error-messages="errorMessages.description"
            outlined
          />
        </div>

        <div>
          <v-textarea
            v-model="photoData.memo"
            label="メモ"
            placeholder="管理画面のみで表示されるメモです。公開画面には表示されません。"
            :class="errorMessages.memo ? 'mb-4' : ''"
            :error-messages="errorMessages.memo"
            outlined
          />
        </div>

        <div>
          <v-text-field
            v-model="photoData.photographer"
            type="text"
            label="撮影者"
            placeholder="撮影者、組織などを入力してください"
            :class="errorMessages.photographer ? 'mb-4' : ''"
            :error-messages="errorMessages.photographer"
            outlined
          />
        </div>

        <div>
          <v-text-field
            v-model="photoData.owner"
            type="text"
            label="権利者"
            placeholder="権利者、組織などを入力してください"
            :class="errorMessages.owner ? 'mb-4' : ''"
            :error-messages="errorMessages.owner"
            outlined
          />
        </div>

        <div>
          <v-textarea
            v-model="photoData.place"
            label="撮影場所"
            placeholder="撮影場所の地名や施設名を入力してくだい"
            :class="errorMessages.place ? 'mb-4' : ''"
            :error-messages="errorMessages.place"
            outlined
          />
        </div>

        <div class="pa-12 mb-8" :style="{ backgroundColor: '#f5f5f5' }">
          <div class="mb-4">
            <v-text-field
              v-model="photoData.latitude"
              label="撮影場所の緯度"
              placeholder="35.681236"
              :class="errorMessages.latitude ? 'mb-4' : ''"
              :error-messages="errorMessages.latitude"
              type="text"
              required
              outlined
              background-color="white"
              hint="※ 小数点以下7桁以降は切り捨てられます。"
              persistent-hint
              @change="(n) => (photoData.latitude = round(Number(n)))"
            />
          </div>

          <div class="mb-4">
            <v-text-field
              v-model="photoData.longitude"
              label="撮影場所の経度"
              placeholder="139.767124"
              type="text"
              :class="errorMessages.longitude ? 'mb-4' : ''"
              :error-messages="errorMessages.longitude"
              required
              outlined
              background-color="white"
              hint="※ 小数点以下7桁以降は切り捨てられます。"
              persistent-hint
              @change="(n) => (photoData.longitude = round(Number(n)))"
            />
          </div>

          <v-radio-group v-model="selectedMap" row>
            <v-radio label="OpenStreetMap" value="osm" />
            <v-radio
              label="Google Maps"
              value="gmap"
              @click="isShowConfirmDialog = true"
            />
          </v-radio-group>

          <div>
            <form class="d-flex mb-2" @submit.prevent="searchLatLng">
              <v-text-field
                v-model="address"
                label="撮影場所を検索"
                placeholder="地名、施設名で検索できます"
                type="text"
                outlined
                clearable
                hint="※ デフォルトはOpenStreetMapの場所検索です。地図をGoogle Mapsに切り替えるとGoogle Mapsの場所検索になります。"
                persistent-hint
              />
              <v-btn
                depressed
                height="56px"
                color="primary"
                @click="
                  () => {
                    selectedMap === 'osm'
                      ? searchLatLngLeaflet()
                      : searchLatLng();
                  }
                "
              >
                検索
              </v-btn>
            </form>
          </div>

          <div
            v-show="selectedMap === 'osm'"
            id="leaflet"
            :style="{ height: '360px' }"
          />
          <div
            v-show="selectedMap === 'gmap'"
            id="gmap"
            :style="{ height: '360px' }"
          >
            <GmapMap
              v-if="currentLocation || position"
              ref="mapRef"
              :center="currentLocation || position"
              :zoom="12"
              :options="{ draggableCursor: 'default' }"
              :style="{ width: '100%', height: '100%' }"
              @click="getStatusUseGoogleMaps"
            >
              <!-- 現在地に青いマーカーを置く -->
              <GmapMarker
                v-if="currentLocation"
                :position="currentLocation"
                :icon="'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'"
              />
              <!-- クリックまたは緯度経度で示した場所に赤いマーカーを置く -->
              <GmapMarker
                v-if="photoData.latitude && photoData.longitude"
                :position="position"
                :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
                :draggable="true"
                @dragend="getLatLng($event.latLng.lat(), $event.latLng.lng())"
              />
            </GmapMap>
          </div>
          <v-row justify="end" class="pr-3 mt-4">
            <v-btn large color="primary" @click="toCurrent"> 現在地へ </v-btn>
          </v-row>
        </div>

        <div class="mb-4">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateText"
                :error-messages="errorMessages.date"
                autocomplete="off"
                label="撮影日"
                placeholder="撮影日を入力してください"
                hint="※ キーボードで入力する場合は半角で入力してください。例）2020-01-01"
                persistent-hint
                clearable
                outlined
                v-bind="attrs"
                v-on="on"
                @focus="errorMessages.date = ''"
                @change="validateDateText()"
                @click:clear="dateClear()"
              />
            </template>
            <v-date-picker
              v-model="date"
              locale="jp-ja"
              :day-format="(date) => new Date(date).getDate()"
              @change="dateMenu = false"
            />
          </v-menu>
        </div>
        <div class="mb-4">
          <v-menu
            ref="menu"
            v-model="timeMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="timeText"
                :error-messages="errorMessages.time"
                autocomplete="off"
                label="撮影時刻"
                placeholder="撮影時刻を入力してください"
                hint="※ キーボードで入力する場合は半角で入力してください。例）12:00"
                persistent-hint
                clearable
                outlined
                v-bind="attrs"
                v-on="on"
                @focus="errorMessages.time = ''"
                @change="validateTimeText()"
                @click:clear="timeClear()"
              />
            </template>
            <v-time-picker
              v-if="timeMenu"
              v-model="time"
              @click:minute="$refs.menu.save(time)"
            />
          </v-menu>
        </div>

        <div>
          <div>
            <v-select
              v-model="photoData.license"
              label="ライセンスを選択してください"
              :items="license"
              item-text="label"
              item-value="value"
              :class="errorMessages.license ? 'mb-4' : ''"
              :error-messages="errorMessages.license"
              prepend-icon="mdi-information-outline"
              outlined
              @click:prepend="handleLicensesGuide"
            />
          </div>
          <div v-if="showLicensesGuide" class="mb-4 mt-n6">
            <v-card>
              <v-card-text>
                <p>
                  下記のライセンスを選択することで、著作権を保持したまま作品を自由に流通させることができます
                </p>
                <ul class="mb-3">
                  <li>・CC BY</li>
                  <li>・CC BY-SA</li>
                </ul>
                <v-btn
                  text
                  color="#1976d2"
                  title="外部サイト：クリエイティブ・コモンズ・ライセンスを新規タブで開きます"
                  @click="openLicensesPage"
                  >クリエイティブ・コモンズ・ライセンスの詳細はこちら
                  <v-icon color="#1976d2" class="pa-0"
                    >mdi-chevron-right</v-icon
                  >
                </v-btn>
                <div>
                  <v-btn text color="#1976d2" @click="optOutLicensesGuide"
                    >今後表示しない</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>

        <div v-if="photoData.license === 'etc'">
          <v-text-field
            v-model="photoData.license_description"
            type="text"
            label="ライセンス自由入力"
            placeholder="選択肢にない場合のライセンスを入力してください"
            :class="errorMessages.license_description ? 'mb-4' : ''"
            :error-messages="errorMessages.license_description"
            outlined
          />
        </div>

        <div class="mb-8">
          <v-combobox
            v-model="selectedTagsForCombobox"
            label="タグ"
            :items="tagsForCombobox"
            multiple
            chips
            outlined
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click:close="removeTag(item)"
              >
                {{ item }}
              </v-chip>
            </template>
          </v-combobox>
        </div>

        <div>
          <v-select
            v-model="photoData.status"
            label="ステータス"
            :items="status"
            item-text="label"
            item-value="value"
            :class="errorMessages.status ? 'mb-4' : ''"
            :error-messages="errorMessages.status"
            outlined
          />
        </div>
      </div>

      <hr class="mt-8 mb-4" />

      <div class="mb-4">
        <v-checkbox
          v-model="photoData.enable_location_opendata"
          label="緯度と経度をオープンデータとして公開する"
          hide-details
          :disabled="inUseGoogleMaps"
          prepend-icon="mdi-information-outline"
          @click:prepend="isShowNoteDialog = true"
        />
      </div>

      <v-col>
        <v-row v-if="$route.meta === 'new'" justify="end">
          <v-btn color="primary" large @click="save"> 登録 </v-btn>
        </v-row>
        <v-row v-if="$route.meta === 'edit'" justify="space-between">
          <v-btn color="red" large outlined @click="toggleModal">
            削除する
          </v-btn>
          <v-btn color="primary" large @click="edit"> 上書き保存 </v-btn>
        </v-row>
      </v-col>
    </section>

    <v-dialog
      v-model="isShowNoteDialog"
      max-width="400"
      :style="{ zIndex: 1000 }"
    >
      <v-card class="py-12 px-10">
        <v-card-text>
          Google Mapsで取得した緯度経度は、Google
          Mapsのライセンス上、オープンデータとして公開することができません。緯度経度をオープンデータにする場合には、これらがOpenStreetMapなどの他の手法で取得した緯度経度であることを確認してください。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isShowNoteDialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" max-width="400" :style="{ zIndex: 1000 }">
      <v-card class="py-12 px-10">
        <v-card-title>
          Google Mapsで取得した緯度経度はオープンデータにはできません
        </v-card-title>
        <v-card-text>
          オープンデータとして公開する緯度経度を取得するときは、OpenStreetMapをご利用ください。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isShowConfirmDialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="setAcceptGoogleMapsTerms">
            このメッセージを二度と表示しない
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import Cookies from "js-cookie";
import { DateTime } from "luxon";
import EXIF from "exif-js";
import * as VueGoogleMaps from "vue2-google-maps";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import {
  getData,
  createData,
  updateData,
  deleteData,
  startDetections,
  createMosaic,
} from "@/axios";
import ModalConfirmation from "../../components/ModalConfirmation.vue";
import ModalLoading from "../../components/ModalLoading.vue";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const openDataLicense = [
  {
    value: "cc-by",
    label: "CC BY",
  },
  {
    value: "cc-by-sa",
    label: "CC BY-SA",
  },
  {
    value: "cc0",
    label: "CC0",
  },
];

const notOpenDataLicense = [
  {
    value: "cc-by-nc",
    label: "CC BY-NC",
  },
  {
    value: "cc-by-nc-sa",
    label: "CC BY-NC-SA",
  },
  {
    value: "all-rights-reserved",
    label: "All rights reserved",
  },
  {
    value: "unknown",
    label: "不明",
  },
  {
    value: "etc",
    label: "その他",
  },
];
export default {
  name: "Edit",

  components: {
    ModalConfirmation,
    ModalLoading,
  },

  data() {
    return {
      dateMenu: false,
      timeMenu: false,
      dateText: "",
      date: "",
      timeText: "",
      time: "",
      isModal: false,
      loading: false,
      loadingMessage: "",
      showPhotoEdit: false,
      showUploadArea: false,
      showCancel: false,
      showLicensesGuide: false,
      currentLocation: null,
      license: [
        { header: "オープンデータライセンス" },
        ...openDataLicense,
        { divider: true },
        { header: "オープンデータライセンスではありません" },
        ...notOpenDataLicense,
      ],
      status: [
        {
          id: "status01",
          value: "unpublished",
          label: "下書き",
        },
        {
          id: "status02",
          value: "published",
          label: "公開",
        },
      ],
      tags: [],
      selectedTagsForCombobox: [],
      categories: [],
      preview: "",
      previewFileName: "",
      photoDataBackup: {
        image: "",
        image_width: "",
        image_height: "",
        shot_at: "",
        latitude: "",
        longitude: "",
      },
      userName: "",
      photoData: {
        title: "",
        description: "",
        memo: "",
        photographer: "",
        owner: "",
        place: "",
        location_accuracy: "unknown",
        latitude: "",
        longitude: "",
        enable_location_opendata: true,
        shot_at_accuracy: "unknown",
        shot_at: "",
        license: "",
        license_description: "",
        image: "",
        image_width: "",
        image_height: "",
        tags: [],
        categories: [],
        status: "unpublished",
      },
      errorMessages: {
        title: "",
        description: "",
        memo: "",
        photographer: "",
        owner: "",
        place: "",
        latitude: "",
        longitude: "",
        shot_at: "",
        license: "",
        license_description: "",
        image: "",
        status: "",
        date: "",
        time: "",
      },
      japaneseItems: {
        title: "タイトル",
        description: "写真の説明",
        memo: "メモ",
        photographer: "撮影者",
        owner: "権利者",
        place: "撮影場所",
        latitude: "撮影場所の緯度",
        longitude: "撮影場所の経度",
        shot_at: "撮影日時",
        license: "ライセンス",
        license_description: "ライセンス自由入力",
        image: "写真",
        status: "ステータス",
      },
      newRequired: ["title", "license", "image", "status"],
      editRequired: ["title", "license", "status"],
      address: "",
      mosaicIds: [],
      mosaicPhoto: "",
      mosaicPreviewKey: "mosaic",
      initialCoordinates: {
        // 初期座標：東京駅
        latitude: 35.681236,
        longitude: 139.767124,
      },
      map: null,
      marker: null,
      isShowNoteDialog: false,
      isShowConfirmDialog: false,
      selectedMap: "osm",
      inUseGoogleMaps: false,
    };
  },
  computed: {
    position() {
      if (this.photoData.latitude && this.photoData.longitude) {
        return {
          lat: this.round(Number(this.photoData.latitude)),
          lng: this.round(Number(this.photoData.longitude)),
        };
      } else if (this.currentLocation) {
        return this.currentLocation;
      } else {
        return {
          lat: this.initialCoordinates.latitude,
          lng: this.initialCoordinates.longitude,
        };
      }
    },
    isValid() {
      const errors = Object.keys(this.errorMessages).filter((key) => {
        return this.errorMessages[key].length !== 0;
      });
      return errors.length === 0;
    },
    tagsForCombobox() {
      return this.tags.map((v) => v.label);
    },
    confirmDialog() {
      return (
        this.isShowConfirmDialog &&
        !window.localStorage.getItem("accept_google_maps_terms")
      );
    },
  },

  watch: {
    $route() {
      if (this.$route.meta === "new") {
        this.reset();
        this.photoData = {
          title: "",
          description: "",
          memo: "",
          photographer: "",
          owner: "",
          place: "",
          location_accuracy: "unknown",
          latitude: "",
          longitude: "",
          enable_location_opendata: true,
          shot_at_accuracy: "unknown",
          shot_at: "",
          license: "",
          license_description: "",
          image: "",
          image_width: "",
          image_height: "",
          tags: [],
          categories: [],
          status: "unpublished",
        };
        this.toPosition(this.position);
      }
      if (this.$route.meta === "edit") {
        this.uploadCancel();
        this.showPhotoEdit = true;
        this.reset();
        this.getPhoto();
        this.toPosition(this.position);
      }
    },
    "photoData.license"(value) {
      if (!Cookies.get("optOutLicensesGuide")) {
        this.showLicensesGuide = !openDataLicense.find(
          (v) => v.value === value
        );
      }
    },
    "photoData.latitude"(value) {
      if (
        Number.isFinite(Number(value)) &&
        value !== "" &&
        this.photoData.latitude
      ) {
        this.toPosition(this.position);
      }
    },
    "photoData.longitude"(value) {
      if (
        Number.isFinite(Number(value)) &&
        value !== "" &&
        this.photoData.longitude
      ) {
        this.toPosition(this.position);
      }
    },
    dateText(value) {
      if ([null, ""].includes(value)) return;
      if (
        this.dateRegex(value) &&
        DateTime.fromFormat(value.trim(), "yyyy-MM-dd").isValid
      ) {
        this.date = value.trim();
      }
    },
    date(value) {
      if ([null, ""].includes(value)) return;
      if (
        this.dateRegex(value) &&
        DateTime.fromFormat(value.trim(), "yyyy-MM-dd").isValid
      ) {
        this.dateText = value;
        this.errorMessages.date = "";
      }
    },
    timeText(value) {
      if ([null, ""].includes(value)) return;
      if (this.timeRegex(value)) {
        this.time = value.trim();
      }
    },
    time(value) {
      if ([null, ""].includes(value)) return;
      if (this.timeRegex(value)) {
        this.timeText = value;
        this.errorMessages.time = "";
      }
    },
    "$store.state.photo.faceIds"(value) {
      this.mosaicIds = value;
    },
    "$store.state.photo.isProcessingMosaic"(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.getMosaicPhoto();
      } else {
        this.mosaicPhoto = "";
      }
    },
    inUseGoogleMaps(value) {
      this.photoData.enable_location_opendata = !value;
    },
  },

  mounted() {
    this.getTags();
    this.getCategories();
    if (this.$route.meta === "edit") {
      this.getPhoto();
      this.showPhotoEdit = true;
    }
    this.map = L.map("leaflet").setView(
      [this.initialCoordinates.latitude, this.initialCoordinates.longitude],
      12
    );
    this.marker = L.marker(
      [this.initialCoordinates.latitude, this.initialCoordinates.longitude],
      { draggable: true }
    ).addTo(this.map);
    this.map.getPanes().mapPane.style.zIndex = 1;
    const osm = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    });
    osm.addTo(this.map);
    const onMapClick = (e) => {
      this.inUseGoogleMaps = false;
      this.marker.setLatLng(e.latlng);
      this.getLatLng(e.latlng.lat, e.latlng.lng);
    };
    this.map.on("click", onMapClick);
    this.marker.on("dragend", (e) =>
      this.getLatLng(e.target._latlng.lat, e.target._latlng.lng)
    );
  },

  methods: {
    setAcceptGoogleMapsTerms() {
      window.localStorage.setItem("accept_google_maps_terms", "true");
      this.isShowConfirmDialog = false;
    },
    removeTag(item) {
      this.selectedTagsForCombobox = this.selectedTagsForCombobox.filter(
        (v) => v !== item
      );
    },
    openLicensesPage(e) {
      e.preventDefault();
      window.open("https://creativecommons.jp/licenses/");
    },
    optOutLicensesGuide(e) {
      e.preventDefault();
      Cookies.set("optOutLicensesGuide", 1);
      this.showLicensesGuide = false;
    },
    handleLicensesGuide() {
      if (!Cookies.get("optOutLicensesGuide")) {
        this.showLicensesGuide = !this.showLicensesGuide;
      } else {
        window.open("https://creativecommons.jp/licenses/");
      }
    },
    dateRegex(date) {
      return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(date.trim());
    },
    timeRegex(time) {
      return /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/.test(time.trim());
    },
    validateDateText() {
      if ([null, ""].includes(this.dateText)) return;
      if (
        !this.dateRegex(this.dateText) ||
        !DateTime.fromFormat(this.dateText.trim(), "yyyy-MM-dd").isValid
      ) {
        this.errorMessages.date = "撮影日が不正な値です。";
      } else {
        this.errorMessages.date = "";
      }
    },
    validateTimeText() {
      if ([null, ""].includes(this.timeText)) return;
      if (!this.timeRegex(this.timeText)) {
        this.errorMessages.time = "撮影時刻が不正な値です。";
      } else {
        this.errorMessages.time = "";
      }
    },
    dateClear() {
      this.dateText = null;
      this.date = null;
      this.errorMessages.date = "";
    },
    timeClear() {
      this.timeText = null;
      this.time = null;
      this.errorMessages.time = "";
    },
    // 共通整備項目で、経度、緯度は小数点以下6桁となっているため、統一する
    round(num) {
      return Math.floor(num * 1000000) / 1000000;
    },
    getTags() {
      getData("tags").then((res) => {
        this.tags = res.data;
      });
    },
    getCategories() {
      getData("categories").then((res) => {
        this.categories = res.data;
      });
    },
    getPhoto() {
      getData(`photos/${this.$route.params.photoId}`)
        .then((res) => {
          if (res.categories.length > 0) {
            res.categories = res.categories.map((v) => v.id);
          }
          this.photoData = res;
          this.userName = res.user.name;
          if (this.photoData.shot_at) {
            const shot_at = DateTime.fromISO(this.photoData.shot_at)
              .toFormat("yyyy-MM-dd HH:mm")
              .split(" ");
            this.date = shot_at[0];
            this.dateText = shot_at[0];
            this.time = shot_at[1];
            this.timeText = shot_at[1];
          }
          if (this.photoData.tags) {
            this.selectedTagsForCombobox = this.photoData.tags.map(
              (v) => v.label
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleModal() {
      this.isModal = !this.isModal;
    },
    photoEdit() {
      this.photoDataBackup.image = this.photoData.image;
      this.photoDataBackup.image_width = this.photoData.image_width;
      this.photoDataBackup.image_height = this.photoData.image_height;
      this.photoDataBackup.shot_at = this.photoData.shot_at;
      this.photoDataBackup.latitude = this.photoData.latitude;
      this.photoDataBackup.longitude = this.photoData.longitude;
      this.showPhotoEdit = false;
      this.showUploadArea = true;
      this.showCancel = true;
    },
    uploadCancel() {
      this.reset();
      this.showCancel = false;
      this.showUploadArea = false;
      this.showPhotoEdit = true;
    },
    selectFile() {
      this.$refs.file.click();
    },
    reset() {
      this.preview = "";
      this.previewFileName = "";
      this.photoData.image = this.photoDataBackup.image || "";
      this.photoData.image_width = this.photoDataBackup.image_width || "";
      this.photoData.image_height = this.photoDataBackup.image_height || "";
      this.photoData.shot_at = this.photoDataBackup.shot_at || "";
      this.photoData.latitude = this.photoDataBackup.latitude || "";
      this.photoData.longitude = this.photoDataBackup.longitude || "";
      this.toPosition(this.position);
      Object.keys(this.errorMessages).forEach((key) => {
        this.errorMessages[key] = "";
      });
      this.mosaicIds = [];
      this.mosaicPhoto = "";
      this.mosaicPreviewKey = "mosaic";
    },
    toPosition(position) {
      this.marker.setLatLng([position.lat, position.lng]);
      this.map.setView([position.lat, position.lng]);
    },
    onFileChange(event) {
      this.reset();
      const files = event.target.files || event.dataTransfer.files;
      if (files.length === 0) {
        this.reset();
        return;
      }
      if (!files[0].type.match("image.*")) {
        alert("写真を選択してください。");
        this.reset();
        return;
      }
      this.photoData.image = files[0];
      this.showUploadImage(files[0]);
    },
    showUploadImage(file) {
      const reader = new FileReader();
      const self = this;
      EXIF.getData(file, function () {
        const exif = EXIF.getAllTags(this);
        if (exif["DateTimeOriginal"]) {
          // datepicker, timepicker用の形式にする
          const shot_at = exif["DateTimeOriginal"].split(/[:' ']/);
          self.date = `${shot_at[0]}-${shot_at[1]}-${shot_at[2]}`;
          self.dateText = `${shot_at[0]}-${shot_at[1]}-${shot_at[2]}`;
          self.time = `${shot_at[3]}:${shot_at[4]}`;
          self.timeText = `${shot_at[3]}:${shot_at[4]}`;
        }
        if (
          exif["GPSLatitude"] &&
          exif["GPSLatitudeRef"] &&
          exif["GPSLongitude"] &&
          exif["GPSLongitudeRef"]
        ) {
          self.photoData.latitude = self.round(
            self.getGpsFromExif(exif["GPSLatitude"], exif["GPSLatitudeRef"])
          );
          self.photoData.longitude = self.round(
            self.getGpsFromExif(exif["GPSLongitude"], exif["GPSLongitudeRef"])
          );
        }
      });
      this.previewFileName = file.name;

      reader.onload = () => {
        this.preview = reader.result;
        // 幅、高さ取得がフロントなら以下で取得
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          this.photoData.image_width = image.naturalWidth;
          this.photoData.image_height = image.naturalHeight;
        };
      };
      reader.readAsDataURL(file);
    },
    startDetections() {
      startDetections(this.photoData.image);
      this.mosaicPhoto = "";
      this.mosaicIds = [];
    },
    createMosaic() {
      createMosaic(this.$store.state.photo.uuid, this.mosaicIds);
    },
    getMosaicPhoto() {
      const request = new XMLHttpRequest();
      request.open("GET", this.$store.state.photo.mosaicImgFileUrl, true);
      request.setRequestHeader("Pragma", "no-cache");
      request.setRequestHeader("Cache-Control", "no-cache");
      request.setRequestHeader(
        "If-Modified-Since",
        "Thu, 01 Jun 1970 00:00:00 GMT"
      );
      request.responseType = "blob";
      request.onload = () => {
        const reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = (e) => {
          this.mosaicPhoto = e.target.result;
        };
      };
      request.send();
    },
    replacePhotoToMosaic() {
      const arr = this.mosaicPhoto.split(",");
      const byteString = atob(arr[1]);
      const mimeType = arr[0].match(/:(.*?);/)[1];
      const l = byteString.length;
      let content = new Uint8Array(l);
      for (let i = 0; l > i; i++) {
        content[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([content], {
        type: mimeType,
      });
      this.photoData.image = new File([blob], this.previewFileName, {
        type: mimeType,
      });
      this.preview = this.mosaicPhoto;
      this.mosaicPhoto = "";
      this.mosaicIds = [];
    },
    searchLatLng() {
      const maps = VueGoogleMaps.gmapApi().maps;
      const geocoder = maps.Geocoder.prototype;
      geocoder.geocode({ address: this.address }, (results, status) => {
        if (status === maps.GeocoderStatus.OK && results.length > 0) {
          this.photoData.latitude = this.round(
            results[0].geometry.location.lat()
          );
          this.photoData.longitude = this.round(
            results[0].geometry.location.lng()
          );
        }
      });
    },
    async searchLatLngLeaflet() {
      const provider = new OpenStreetMapProvider();
      const results = await provider.search({ query: this.address });
      const lat = results[0].y;
      const lng = results[0].x;
      this.getLatLng(lat, lng);
      this.marker.setLatLng([lat, lng]);
      this.map.setView([lat, lng]);
    },
    getStatusUseGoogleMaps(e) {
      this.inUseGoogleMaps = true;
      this.getLatLng(e.latLng.lat(), e.latLng.lng());
    },
    getGpsFromExif(exifCoord, hemi) {
      const flip = hemi === "W" || hemi === "S" ? -1 : 1;
      return flip * (exifCoord[0] + exifCoord[1] / 60 + exifCoord[2] / 3600);
    },
    getLatLng(lat, lng) {
      this.photoData.latitude = this.round(lat);
      this.photoData.longitude = this.round(lng);
    },
    async toCurrent() {
      let position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const lat = this.round(position.coords.latitude);
      const lng = this.round(position.coords.longitude);
      this.currentLocation = { lat, lng };
      this.toPosition(this.currentLocation);
    },
    handleFormData() {
      const formData = new FormData();
      Object.keys(this.photoData).forEach((key) => {
        if (Array.isArray(this.photoData[key])) {
          this.photoData[key].forEach((v) => {
            formData.append(key + "[]", v);
          });
        } else if (key === "shot_at" && this.photoData.shot_at) {
          formData.append(key, DateTime.fromSQL(this.photoData[key]).toISO());
        } else if (this.photoData[key] === null) {
          // Laravelでは"null"(string)になる為。''(空白)で送るとnullになる。
          formData.append(key, "");
        } else {
          formData.append(key, this.photoData[key]);
        }
      });
      return formData;
    },
    deleteItem() {
      this.loading = true;
      this.loadingMessage = "削除中...";
      deleteData(`photos/${this.$route.params.photoId}`)
        .then(() => {
          this.loading = false;
          this.loadingMessage = "";
          this.$store.dispatch("snackbar/setSnackbar", {
            message: `「${this.photoData.title}」を削除しました。`,
            color: "success",
            timeout: 2000,
          });
          if (this.$route.params.from) {
            const from = Object.assign({}, this.$route.params.from);
            const params = Object.assign({}, from);
            // 空文字のクエリーを無くす：例）words=''
            Object.keys(params).forEach((key) => {
              if (params[key] === "") delete params[key];
            });
            this.$router.push({ name: "photos", query: params });
          } else {
            this.$router.push({ name: "photos" });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.loadingMessage = "";
          console.log(err);
        });
    },
    setShotAt() {
      // dateとtimeを合わせる
      // 秒数を付け、DBの保存形式にする
      this.photoData.shot_at = (() => {
        if (this.date) {
          if (this.time) {
            return `${this.date.trim()} ${this.time.trim()}:00`;
          } else {
            return `${this.date.trim()} 00:00:00`;
          }
        } else {
          return null;
        }
      })();
    },
    handleTagsData() {
      return new Promise((resolve, reject) => {
        const newTags = [];
        this.selectedTagsForCombobox.forEach((v) => {
          if (!this.tags.find((t) => t.label === v)) {
            newTags.push(v);
          }
        });
        if (newTags.length > 0) {
          createData("tags/bulk", { tags: newTags })
            .then((res) => {
              this.photoData.tags = res.data
                .filter((v) => this.selectedTagsForCombobox.includes(v.label))
                .map((v) => v.id);
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          this.photoData.tags = this.tags
            .filter((v) => this.selectedTagsForCombobox.includes(v.label))
            .map((v) => v.id);
          resolve(true);
        }
      });
    },
    edit() {
      this.setShotAt();
      this.validate();
      if (this.isValid) {
        this.loading = true;
        this.loadingMessage = "更新中...";
        this.handleTagsData()
          .then(() => {
            const formData = this.handleFormData();
            if (typeof formData.get("image") === "string") {
              // 写真の編集をしていない時は'string'となっておりAPIのバリデーションに引っかかる為送らない
              formData.delete("image");
            }
            if (typeof formData.get("video") === "string") {
              // 動画の編集をしていない時は'string'となっておりAPIのバリデーションに引っかかる為送らない
              formData.delete("video");
            }
            return updateData(`photos/${this.$route.params.photoId}`, formData);
          })
          .then(() => {
            this.loading = false;
            this.loadingMessage = "";
            this.$store.dispatch("snackbar/setSnackbar", {
              message: `「${this.photoData.title}」を更新しました。`,
              color: "success",
              timeout: 2000,
            });
            if (this.$route.params.from) {
              const params = Object.assign({}, this.$route.params.from);
              // 空文字のクエリーを無くす：例）words='', tags[]=''
              Object.keys(params).forEach((key) => {
                if (
                  params[key] === "" ||
                  (Array.isArray(params[key]) && params[key].length === 0)
                ) {
                  delete params[key];
                }
                // クエリー用のKeyに変える
                if (key === "tags" && Array.isArray(params.tags)) {
                  params["tags[]"] = params["tags"].slice();
                  delete params["tags"];
                }
                if (key === "categories" && Array.isArray(params.categories)) {
                  params["categories[]"] = params["categories"].slice();
                  delete params["categories"];
                }
              });
              this.$router.push({ name: "photos", query: params });
            } else {
              this.$router.push({ name: "photos" });
            }
          })
          .catch((err) => {
            this.loading = false;
            this.loadingMessage = "";
            console.log(err);
          });
      } else {
        this.$nextTick(() => {
          // エラーメッセージのある入力欄までスクロール
          const errorElement = document.querySelector(".error--text");
          if (errorElement) {
            errorElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        });
      }
    },
    save() {
      this.setShotAt();
      this.validate();
      if (this.isValid) {
        this.loading = true;
        this.loadingMessage =
          this.photoData.status === "published"
            ? "公開中..."
            : "下書き保存中...";

        this.handleTagsData()
          .then(() => {
            const formData = this.handleFormData();
            return createData("photos", formData);
          })
          .then(() => {
            this.loading = false;
            this.loadingMessage = "";
            const message =
              this.photoData.status === "published"
                ? `「${this.photoData.title}」を公開しました。`
                : `「${this.photoData.title}」を下書きで保存しました。`;
            this.$store.dispatch("snackbar/setSnackbar", {
              message,
              color: "success",
              timeout: 2000,
            });
            this.$router.push("/photos");
          })
          .catch((err) => {
            this.loading = false;
            this.loadingMessage = "";
            console.log(err);
          });
      } else {
        this.$nextTick(() => {
          // エラーメッセージのある入力欄までスクロール
          const errorElement = document.querySelector(".error--text");
          if (errorElement) {
            errorElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        });
      }
    },
    validate() {
      Object.keys(this.errorMessages).forEach((key) => {
        if (["date", "time"].includes(key)) return;
        this.errorMessages[key] = "";
      });
      Object.keys(this.photoData).forEach((key) => {
        this.checkRequired(
          key,
          this.$route.meta === "new" ? this.newRequired : this.editRequired
        );
        this.checkMaxLength(key, 100, [
          "title",
          "photographer",
          "owner",
          "license_description",
        ]);
        this.checkMaxLength(key, 1000, ["description", "memo", "place"]);
        this.checkNumber(key, ["latitude", "longitude"]);
      });
    },
    checkRequired(key, requiredList) {
      if (
        requiredList.indexOf(key) !== -1 &&
        this.photoData[key].length === 0
      ) {
        this.errorMessages[key] = `${this.japaneseItems[key]}は必須項目です。`;
      }
    },
    checkMaxLength(key, max, targets) {
      targets.forEach((item) => {
        if (
          key === item &&
          this.photoData[key] &&
          max < this.photoData[key].length
        ) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は${max}文字以下で記入して下さい。`;
        }
      });
    },
    checkNumber(key, targets) {
      targets.forEach((item) => {
        if (
          key === item &&
          !Number.isFinite(Number(this.photoData[key])) &&
          this.photoData[key] !== ""
        ) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は半角数字で記入して下さい。`;
        }
      });
    },
  },
};
</script>
